import React from 'react'
import { Image } from '../../components/Image'
import {
  MainSection,
  MainLabel,
  Grid1md2,
  Card,
  SquareSpacer,
  CardLink,
  H2,
  H3Like,
} from './common'
import imgKurs from './img/_cover-half.jpg'
import imgSchoolBg from './img/manager-school-bg.jpg'
import imgSchool from './img/manager-school.png'

export default function WhereToStudy() {
  return (
    <MainSection id='where-to-study'>
      <H2 className='mb-6'>Где научиться</H2>
      <Grid1md2>
        <CardLink href='http://bureau.ru/courses/fff/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <SquareSpacer />
            <Image
              src={imgKurs}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '102%',
                width: 'auto', // Ensures the image maintains its aspect ratio
                maxWidth: 'none',
              }}
              alt='Обложка курса «Управление проектами, людьми и собой 2.0»'
            />
          </Card>
          <MainLabel>Практический курс</MainLabel>
          <H3Like className='hoverable pr-5'>
            Управление проектами, людьми и&nbsp;собой&nbsp;2.0
          </H3Like>
        </CardLink>
        <CardLink href='https://bureau.ru/school/managers/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <SquareSpacer />
            <Image
              src={imgSchoolBg}
              style={{
                position: 'absolute',
                top: 0,
                left: '-30%',
                height: '100%',
                maxWidth: 'unset',
              }}
              alt='Обложка школы руководителей'
            />
            <Image
              src={imgSchool}
              style={{
                position: 'absolute',
                top: '16%',
                left: '-4%',
              }}
              alt='Обложка школы руководителей'
            />
          </Card>
          <MainLabel>Школа</MainLabel>
          <H3Like className='hoverable'>Школа руководителей</H3Like>
        </CardLink>
      </Grid1md2>
    </MainSection>
  )
}
