import React from 'react'

import { H2 as BaseH2, H3 as BaseH3 } from '../index/common'

export const TextBlock = (props) => (
  <div className={`md:w-9/12 ${props.className || ''}`} {...props}>
    {props.children}
  </div>
)

export const Grid1md2 = (props) => (
  <div
    {...props}
    className={
      'grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-0 w-full md:w-11/12 lg:w-9/12' +
      props.className
    }
  />
)

export const Title = (props) => (
  <div
    {...props}
    className={`text-4xl max-w-2xl leading-tight mb-3 ${props.className || ''}`}
  />
)

// Was not tested, check vertical margins on first use
export const H2 = (props) => (
  <BaseH2 {...props} className={`mb-3 mt-8 ${props.className || ''}`} />
)

export const H3 = (props) => (
  <BaseH3 {...props} className={`mb-2 mt-6 ${props.className || ''}`} />
)

export const P = (props) => (
  <p {...props} className={`mb-2 ${props.className || ''}`} />
)

export const A = (props) => (
  <a
    {...props}
    target={props.target || '_self'} // Default to `_self` unless specified
    rel={props.target === '_blank' ? 'noopener noreferrer' : undefined} // Add only for `_blank`
    className={`link link-cyan transition ${props.className || ''}`}
  >
    {props.children}
  </a>
)

export const StarIcon = ({ className = '' }) => (
  <span
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 18'
      width='18'
      height='18'
      className={className}
      style={{ verticalAlign: 'baseline', display: 'inline-block' }}
      aria-hidden='true' // Hide from assistive technologies
    >
      <path
        d='m9 15-4.22486707 2.9399214c-.31733029.2208178-.75358556.1425791-.97440341-.1747512-.12225598-.1756902-.15741235-.3976588-.09543125-.6025292l1.49047566-4.926573-4.1015871-3.10960171c-.30807072-.23356257-.36847151-.6726431-.13490894-.98071381.12931209-.17056359.32955283-.27259134.54354941-.27695226l5.14603169-.1048682 1.68994683-4.86176091c.12693211-.36516747.52585764-.55829537.89102511-.43136326.20217524.07027606.36108719.22918801.43136325.43136326l1.68994682 4.86176091 5.1460317.1048682c.3865191.00787666.6934694.32759758.6855927.71411666-.0043609.21399658-.1063886.41423732-.2769522.54354941l-4.1015871 3.10960171 1.4904756 4.926573c.1119498.3700355-.09727.7607618-.4673055.8727116-.2048704.0619811-.426839.0268248-.6025291-.0954312z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
    <span
      style={{
        position: 'absolute',
        left: '-9999px',
        userSelect: 'all',
      }}
    >
      ⭐️
    </span>
  </span>
)

export const LockIcon = ({ className = '' }) => (
  <span
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 18'
      width='12'
      height='18'
      className={className}
      style={{ verticalAlign: 'baseline', display: 'inline-block' }}
      aria-hidden='true' // Hide from assistive technologies
    >
      <path
        d='m10 8c1.1045695 0 2 .8954305 2 2v6c0 1.1045695-.8954305 2-2 2h-8c-1.1045695 0-2-.8954305-2-2v-6c0-1.1045695.8954305-2 2-2v-2c0-2.20253797 1.8542576-4 4-4s4 1.80399275 4 4zm-2 0v-2c0-1.14714177-.8954305-2-2-2s-2 .85387271-2 2v2z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
    <span
      style={{
        position: 'absolute',
        left: '-9999px',
        userSelect: 'all',
      }}
    >
      🔒
    </span>
  </span>
)

export const JollyRogerIcon = ({
  className = '',
  width = '1em',
  height = '0.93em',
  style = {},
}) => (
  <span
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      ...style, // Merge additional styles
    }}
    className={className}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 102 95'
      width={width}
      height={height}
      className={className}
      style={{ verticalAlign: 'baseline', display: 'inline-block' }}
      aria-hidden='true' // Hide from assistive technologies
    >
      <path
        d='m80.93 93.779999s.440003-3.919998 1.129998-10.100006l-11.839997-2.059998-1.309997 7.210007-26.200005-6.729996-2.059997 7.179993-39.860001-27.869995 18.829998-10.560006 4.07-19.119995 19.000003-8.240005 2.309998-11.199997 45.349998-2.690003c.270005-2.379997.46-3.999992.550004-4.719993.779998-6.25 10.729995-5.650009 10.260002.429993-.470002 6.080001-10.75 89.169998-10.75 89.169998zm-19.279998-70.32c-13.650002-1.669998-17.720002 10.790001-15.32 18.800003 5.119995 1.790001 6.409996 3.110001 5.849998 10.150002l11.949997.199997c.660004-5.930001 1.850006-7.650002 7.730004-8.07 3.559997-7.190003 3.440002-19.400002-10.209999-21.080002zm-3.900002 37.470001-19.080002-11.68c1.480004 5.610001 6.150002 10 12 14.32l-10.139999 3.620002 3.690002 5.909996 13.25-5.330001 12.409996 7 4.450005-5.119995-9.620003-4.930001c5.260002-2.61 11.639999-6.68 14.580002-13zm7.599998-19.220001c-4.879997 0-4.659996-7-.080001-7 4.580001 0 4.540001 7 .080001 7zm-11.059997-.349998c-5.900002 0-5.629997-8.39-.099999-8.39 5.529999 0 5.479996 8.39.099999 8.39z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
    <span
      style={{
        position: 'absolute',
        left: '-9999px',
        userSelect: 'all',
      }}
    >
      🏴‍☠️
    </span>
  </span>
)
