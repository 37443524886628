import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Metrics from './components/Metrics/Metrics'
import Index from './pages/index'
import ChiefPage from './pages/chief/chief'
import Agreement from './pages/agreement'
import Community from './pages/community'
import Conspectus from './pages/conspectus'
import Gpdg from './pages/gpdg'
import GettingProjectDoneGuide from './pages/getting-project-done-guide'
import GettingProjectDoneGuideSuccess from './pages/getting-project-done-guide/success'
import Lifehacks from './pages/lifehacks'
import './App.css'

const App = () => (
  <Router>
    <Switch>
      <Route exact path='/' component={Index} />
      <Route exact path='/chief' component={ChiefPage} />
      <Route exact path='/agreement' component={Agreement} />
      <Route exact path='/community' component={Community} />
      <Route exact path='/conspectus' component={Conspectus} />
      <Route exact path='/gpdg' component={Gpdg} />
      <Route exact path='/lifehacks' component={Lifehacks} />
      <Route
        exact
        path='/getting-project-done-guide'
        component={GettingProjectDoneGuide}
      />
      <Route
        exact
        path='/getting-project-done-guide/success'
        component={GettingProjectDoneGuideSuccess}
      />
    </Switch>
    <Metrics />
  </Router>
)

export default App
