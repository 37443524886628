import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import DebugGrid from '../../components/DebugGrid/DebugGrid'
import { MainSection } from '../index/common'
import {
  Grid1md2,
  TextBlock,
  Title,
  H3,
  P,
  A,
  StarIcon,
  LockIcon,
  JollyRogerIcon,
} from './common'
import Lifehack from './Lifehack'
import imgLifehacks from '../index/img/lifehacks-cover.png'

const navElements = {
  left: (
    <div>
      <a className='font-bold text-cyan hover:text-black transition' href='/'>
        ФФФ.воркс
      </a>
    </div>
  ),
  center: <div></div>,
  right: (
    <div>
      <a
        className='pr-4 text-cyan hover:text-black transition'
        target='_blank'
        rel='noopener noreferrer'
        href='/blog'
      >
        Блог
      </a>
      <a
        className='text-cyan hover:text-black transition'
        target='_blank'
        rel='noopener noreferrer'
        href='/community'
      >
        Сообщество
      </a>
    </div>
  ),
}

export default function LifehacksPage() {
  return (
    <>
      <Helmet>
        <title>🏴‍☠️ Лайфхаки Товеровского</title>
        <meta property='og:url' content='http://fff.works/lifehacks' />
        <meta property='og:title' content='🏴‍☠️ Лайфхаки Товеровского' />
        <meta
          property='og:description'
          content='Сборник проверенных приёмов повышения продуктивности и эффективности. Советы по управлению проектами, людьми и собой.'
        />
        <meta property='og:image' content={imgLifehacks} />
        <meta
          property='og:image:alt'
          content='Обложка страницы «🏴‍☠️ Лайфхаки Товеровского»'
        />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>

      <DebugGrid />

      <Nav {...navElements} />

      <MainSection>
        <TextBlock>
          <Title className='font-bold'>
            <JollyRogerIcon
              width='1.5em'
              height='1.4em'
              style={{ marginLeft: '-0.06em' }}
            />
            &nbsp;Лайфхаки Товеровского
          </Title>
          <P>
            Лайфхак&nbsp;— это приём повышения эффективности в&nbsp;конкретной
            ситуации. Собранные на&nbsp;этой странице лайфхаки проверены
            и&nbsp;одобрены{' '}
            <A href='https://t.me/toverovskiy' target='_blank'>
              Николаем&nbsp;Товеровским
            </A>
            . Некоторые им&nbsp;же и&nbsp;придуманы. Лайфхак дело
            индивидуальное, поэтому не&nbsp;переживайте, если вам какой-то
            «не&nbsp;зайдёт», попробуйте другой.
          </P>
          <P>
            Особенно любимые и&nbsp;хорошо себя зарекомендовавшие лайфхаки
            отмечены{' '}
            <nobr>
              <StarIcon />
              &nbsp;звёздочкой.
            </nobr>{' '}
            <span className='line-through'>Зачёркнутые</span> лайфхаки
            не&nbsp;прошли проверку временем у&nbsp;Николая, но&nbsp;могут
            сработать для&nbsp;вас.
          </P>
          <P>
            Для&nbsp;доступа к&nbsp;некоторым лайфхакам нужна{' '}
            <A href='https://boosty.to/fffworks' target='_blank'>
              подписка на&nbsp;Бусти
            </A>{' '}
            или на&nbsp;книгу «
            <A href='https://bureau.ru/projects/book-fff/' target='_blank'>
              Управление проектами, людьми и&nbsp;собой
            </A>
            », такие лайфхаки под{' '}
            <nobr>
              <LockIcon />
              &nbsp;замком.
            </nobr>
          </P>
        </TextBlock>
        <H3>Проекты</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Срать в&nbsp;ячейки'
              description='Сделать бардак самоприбираемым.'
              link='https://fff.works/blog/all/shit-in-cells/'
              isFavourite
            />
            <Lifehack
              name='Гвозди в&nbsp;гусенице'
              description='Не&nbsp;дать задаче или&nbsp;проекту расползтись.'
              link='https://fff.works/blog/all/caterpillar-nails/'
              isFavourite
            />
            <Lifehack
              name='Создать вакуум'
              description='Перестроить сложную и&nbsp;плохо работающую систему&nbsp;— команду, процесс, код.'
              link='https://boosty.to/fffworks/posts/aff95a5d-bb9e-429b-bff8-cf1fb2a47909'
              isLocked
            />

            <Lifehack
              name='Прямо сейчас'
              description='Выбрать лучшее время для&nbsp;решения задачи.'
              link='https://bureau.ru/soviet/20170717/'
              isFavourite
            />
            <Lifehack
              name='Конвейер'
              description='Изго­то­вить сотню инди­ви­ду­аль­ных визи­ток, обтра­вить тысячу фото­гра­фий, отве­тить десят­и тысячам кли­ен­тов, про­мо­де­ри­ро­вать мил­лион фоток.'
              link='https://bureau.ru/books/fff/164'
              isFavourite
              isLocked
            />
          </div>
          <div>
            <Lifehack
              name='Чат проекта'
              description='Быстро наладить коммуникацию в&nbsp;команде.'
              link='https://boosty.to/fffworks/posts/64fae835-0bc3-404a-ba84-c2553fb5c2ed'
              isLocked
            />
            <Lifehack
              name='Оценка хода проекта с&nbsp;помощью кружочков и&nbsp;квадратиков'
              description='Легко и&nbsp;просто оценить ход работы над&nbsp;проектом.'
              link='https://boosty.to/fffworks/posts/3c3e865c-b1bd-47c2-a958-e8dc55f8bb2e'
              isLocked
            />

            <Lifehack
              name='Лучше по одному'
              description='Сделать много однотипных продуктов.'
              link='https://boosty.to/fffworks/posts/b2054b84-9a6b-4952-ad6b-eaf13ac7be3a'
            />

            <Lifehack
              name='Принцип множественного полезного действия'
              description='Принять решение в&nbsp;условиях неопределённости.'
              link='https://boosty.to/fffworks/posts/99f8a737-04c0-49e8-8b4c-adbc191a01d9'
              isLocked
            />

            <Lifehack
              name='Шаблонизируй это'
              description='Сделать больше, а&nbsp;устать меньше.'
              link='https://www.youtube.com/watch?v=ynr51b3_PFE'
              isFavourite
            />
          </div>
        </Grid1md2>
        <H3>Люди</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Карточка переговоров'
              description='Подготовиться к&nbsp;переговорам.'
              link='https://ksoftware.livejournal.com/273805.html'
              isFavourite
            />
            <Lifehack
              name='Переговоры через посредника'
              description='Не&nbsp;дать эмоциям повлиять на&nbsp;результат переговоров.'
              link='https://ksoftware.livejournal.com/391223.html'
            />

            <Lifehack
              name='Делегировать самоорганизацию'
              description='Контролировать работу исполнителей с&nbsp;минимальными затратами.'
              link='https://boosty.to/fffworks/posts/daf84b64-f58b-4ff2-bd3b-edb3eec68a6d'
              isLocked
            />

            <Lifehack
              name='Не командовать, а&nbsp;корректировать'
              description='Перестать жить в&nbsp;режиме постоянного аврала.'
              link='https://boosty.to/fffworks/posts/b73a38d7-a289-46a2-8fcf-0c887ba36e64'
              isLocked
            />
          </div>
          <div>
            <Lifehack
              name='Голосом на тренировке'
              description='Найти «бесплатное» время для&nbsp;ответов на&nbsp;рабочие вопросы.'
              link='https://boosty.to/fffworks/posts/1f702414-fa95-4440-af5b-37914b6dcf5f'
            />
            <Lifehack
              name='Ограничить&nbsp;их. Рабочие часы'
              description='Найти время для&nbsp;спокойной работы, когда много дел, проектов и&nbsp;команд.'
              link='https://boosty.to/fffworks/posts/1f702414-fa95-4440-af5b-37914b6dcf5f'
              isFavourite
              isLocked
            />
            <Lifehack
              name='Все смотрю'
              description='Настроить контроль сложной системы.'
              link='https://fff.works/blog/all/all-seeing-manager/'
            />
          </div>
        </Grid1md2>
        <H3>Задачи</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Календарь дел'
              description='Вести задачи в&nbsp;обычном календаре, чтобы всё успевать.'
              link='https://boosty.to/fffworks/posts/d0ef5f40-70f5-459e-b12c-512a28797a6b'
              isFavourite
            />

            <Lifehack
              name='Заглушка в&nbsp;календаре'
              description='Защитить время для&nbsp;важных дел.'
              link='https://boosty.to/fffworks/posts/edb973cd-c1a4-48fa-bad0-76155f39bffc'
              isLocked
            />

            <Lifehack
              name='План дня днём'
              description='Планировать дела на&nbsp;завтра и&nbsp;не&nbsp;«забивать» на&nbsp;это.'
              link='https://ksoftware.livejournal.com/390038.html'
            />
            <Lifehack
              name='Буферы для&nbsp;совещаний'
              description='Снизить тревогу от&nbsp;совещаний.'
              link='https://boosty.to/fffworks/posts/53a4f714-627e-4b3c-875a-e52d31a43970'
            />
            <Lifehack
              name='Взять новый тудушник'
              description='Выдохнуть и&nbsp;разгрести завалы дел, если не&nbsp;получается взять себя в&nbsp;руки.'
              link='https://boosty.to/fffworks/posts/70a3c74c-9708-4577-90e2-2cbd8c5109e8'
              isLocked
            />
          </div>
          <div>
            <Lifehack
              name='Сделать завтра'
              description='Научиться регулярно «сделывать» все&nbsp;дела за&nbsp;день.'
              link='https://bureau.ru/books/fff/221'
              isFavourite
              isLocked
            />

            <Lifehack
              name='Закрытый список'
              description='Не&nbsp;копить несделанные дела.'
              link='https://bureau.ru/books/fff/229'
              isLocked
            />

            <Lifehack
              name='Задавить бульдозеризм'
              description='Ещё один способ не&nbsp;копить несделанные дела.'
              link='https://ksoftware.livejournal.com/423661.html'
            />
          </div>
        </Grid1md2>
        <H3>Продуктивность</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Текущая инициатива'
              description='Сделать большую сложную задачу.'
              link='https://fff.works/blog/all/current-initiative/'
              isFavourite
            />
            <Lifehack
              name='Магия регулярных задач'
              description='Не&nbsp;тратить время на планирование повторяющихся событий.'
              link='https://ksoftware.livejournal.com/401092.html'
            />

            <Lifehack
              name='Измерение себя'
              description='Оценить свою работоспособность.'
              link='https://bureau.ru/soviet/20191004/'
              isFavourite
            />

            <Lifehack
              name='Вкачать щупальце'
              description='Прокачать дополнительные навыки.'
              link='https://bureau.ru/soviet/20200410/'
            />
            <Lifehack
              name='Тупые правила'
              description='Быстро принимать решения в&nbsp;типовых ситуациях.'
              link='https://bureau.ru/soviet/20190628/'
              isFavourite
            />
            <Lifehack
              name='Заполнить промежутки'
              description='Сделать мелкие дела «бесплатно» — не&nbsp;занимая время в&nbsp;плане дня.'
              link='https://boosty.to/fffworks/posts/08e3a63b-4e30-4d0c-935a-964b681242e7'
              isFavourite
              isLocked
            />
          </div>
          <div>
            <Lifehack
              name='Пароль на&nbsp;комп'
              description='Помнить о&nbsp;долгосрочных целях и&nbsp;достигать&nbsp;их.'
              link='https://bureau.ru/soviet/20191018/'
              isFavourite
            />

            <Lifehack
              name='Книга под&nbsp;рукой'
              description='Находить время на&nbsp;чтение книг.'
              link='https://bureau.ru/soviet/20200313/'
              isFavourite
            />
            <Lifehack
              name='Ежедневная мантра'
              description='Не&nbsp;забивать на&nbsp;долгосрочные цели.'
              link='https://boosty.to/fffworks/posts/6b38e1bb-fa82-4bbb-b5ba-79774c8c002d'
            />

            <Lifehack
              name='Хедспейс'
              description='Повысить продуктивность с&nbsp;помощью медитации.'
              link='https://ksoftware.livejournal.com/357956.html'
            />

            <Lifehack
              name='Турбосон'
              description='Сделать больше дел за&nbsp;день.'
              link='https://bureau.ru/bb/soviet/20151210/'
            />

            <Lifehack
              name='Дворец памяти'
              description='Разложить информацию в&nbsp;голове и&nbsp;помнить все.'
              link='https://boosty.to/fffworks/posts/e1464faa-45cc-4440-af0b-58b66c254b6e'
              hasFailed
            />

            <Lifehack
              name='Черно-белый режим на&nbsp;мобильном'
              description='Меньше залипать в&nbsp;соцсети.'
              link='https://boosty.to/fffworks/posts/58285ae6-5b79-416d-b1a3-9cb5964b8e76'
              hasFailed
            />
          </div>
        </Grid1md2>
        <H3>Деньги</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Пилить бюджет'
              description='Легко следить за&nbsp;семейным бюджетом, не&nbsp;записывая каждую трату (подходит и&nbsp;для бюджета небольшой компании).'
              link='https://boosty.to/fffworks/posts/118ed30a-b6b8-444e-9753-4a8c7827799b'
              isFavourite
            />
          </div>
          <div>
            <Lifehack
              name='4&nbsp;конверта'
              description='Распределять личные финансы, чтобы на&nbsp;всё&nbsp;хватало.'
              link='https://ksoftware.livejournal.com/206270.html'
              hasFailed={true}
            />
          </div>
        </Grid1md2>
        <H3>Эмоции</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Зафиксировать первые эмоции'
              description='Получить больше пользы от&nbsp;знакомства с&nbsp;чем-то новым.'
              link='https://bureau.ru/soviet/20200605/'
              isFavourite
            />

            <Lifehack
              name='Срочно оставить след'
              description='Не&nbsp;терять идеи.'
              link='https://bureau.ru/soviet/20191101/'
            />

            <Lifehack
              name='Всё просто'
              description='Находить решение сложных задач.'
              link='https://bureau.ru/soviet/20200522/'
            />

            <Lifehack
              name='Преодолеть нехочуху'
              description='Взяться за&nbsp;дело, к&nbsp;которому не&nbsp;лежит душа.'
              link='https://boosty.to/fffworks/posts/975d09e0-bd4c-4ed2-b09e-3b75fc33cd16'
            />
          </div>
          <div>
            <Lifehack
              name='Не ухожу'
              description='Не&nbsp;совершить профессиональное самоубийство в&nbsp;трудной ситуации.'
              link='https://bureau.ru/soviet/20200424/'
              isFavourite
            />

            <Lifehack
              name='Вселенная в&nbsp;голове'
              description='Не&nbsp;сойти с&nbsp;ума в&nbsp;изоляции.'
              link='https://bureau.ru/soviet/20200327/'
            />

            <Lifehack
              name='Сгладить переход'
              description='Поменять жизнь без&nbsp;жестких потрясений.'
              link='https://youtu.be/b6y-x9LDkMw?si=NyRxvQmvZZ9yy0mP'
            />
          </div>
        </Grid1md2>
        <H3>Быт</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Все рядом'
              description='Сэкономить время на&nbsp;перемещениях.'
              link='https://bureau.ru/soviet/20190920/'
              isFavourite
            />

            <Lifehack
              name='Квартира для&nbsp;работы'
              description='Улучшить работу, сон и&nbsp;похудеть.'
              link='https://boosty.to/fffworks/posts/1823d31a-232d-4051-911e-2f1b50ffcd92'
              isLocked
            />
          </div>
          <div>
            <Lifehack
              name='Тележка для&nbsp;покупок'
              description='Ходить за&nbsp;покупками без&nbsp;страданий.'
              link='https://ksoftware.livejournal.com/443081.html'
            />

            <Lifehack
              name='Хороший инструмент'
              description='Сделать круто, даже если руки кривые.'
              link='https://boosty.to/fffworks/posts/82e972d1-0194-44e0-83e6-cda8ab281b77'
              isFavourite
            />
          </div>
        </Grid1md2>
        <H3>Непроверенные, но&nbsp;любопытные лайфхаки</H3>
        <Grid1md2>
          <div>
            <Lifehack
              name='Эспандер для&nbsp;переговоров'
              description='Слушать собеседника, когда эмоции бьют через край.'
              link='https://boosty.to/fffworks/posts/fd359ff0-86c6-46b2-baf8-55a416fc2f83'
            />

            <Lifehack
              name='Никаких баз&nbsp;знаний'
              description='Получать больше пользы от&nbsp;заметок.'
              link='https://boosty.to/fffworks/posts/fff01d39-4f6b-4827-a2d1-836895afebf9'
            />
          </div>
          <div>
            <Lifehack
              name='Большой стол'
              description='Увидеть проект целиком.'
              link='https://boosty.to/fffworks/posts/e1279162-997c-482b-85e2-47f9ac11f08e'
            />
          </div>
        </Grid1md2>
      </MainSection>
    </>
  )
}
