import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import smoothScrollToAnchor from '../../helpers/smoothScrollToAnchor'
import Games from './Games'
import HowItWorks from './HowItWorks'
import Intro from './Intro'
import Subscription from './Subscription'
import Tips from './Tips'
import Tools from './Tools'
import WhereToStart from './WhereToStart'
import WhereToStudy from './WhereToStudy'
import DebugGrid from '../../components/DebugGrid/DebugGrid'

const navElements = {
  left: (
    <div>
      <span className='font-bold'>ФФФ.воркс</span>
    </div>
  ),
  center: (
    <div className='hidden md:block'>
      <span
        role='button'
        tabIndex='-1'
        className='pr-4 text-cyan hover:text-black transition'
        onClick={smoothScrollToAnchor('how-it-works')}
      >
        Как работает
      </span>
      <span
        role='button'
        tabIndex='-1'
        className='pr-4 text-cyan hover:text-black transition'
        onClick={smoothScrollToAnchor('where-to-start')}
      >
        С чего начать
      </span>
      <span
        role='button'
        tabIndex='-1'
        className='pr-0 text-cyan hover:text-black transition'
        onClick={smoothScrollToAnchor('where-to-study')}
      >
        Где научиться
      </span>
    </div>
  ),
  right: (
    <div>
      <a
        className='pr-4 text-cyan hover:text-black transition'
        target='_blank'
        rel='noopener noreferrer'
        href='/blog'
      >
        Блог
      </a>

      <a
        className='text-cyan hover:text-black transition'
        target='_blank'
        rel='noopener noreferrer'
        href='/community'
      >
        Сообщество
      </a>
    </div>
  ),
}

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <title>ФФФ.воркс</title>
        <meta property='og:url' content='http://fff.works/' />
        <meta property='og:title' content='ФФФ.воркс' />
      </Helmet>

      <DebugGrid />

      <Nav {...navElements} />
      <Intro />
      <HowItWorks />
      <Tips />
      <WhereToStart />
      <Games />
      <WhereToStudy />
      <Tools />
      <Subscription />
    </>
  )
}
