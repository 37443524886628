import React from 'react'

import { StarIcon, LockIcon } from './common'

const Lifehack = ({
  name,
  description,
  link,
  isFavourite = false,
  isLocked = false,
  hasFailed = false,
}) => (
  <div className={'mb-2 '}>
    <a
      className={`${hasFailed ? 'line-through' : ''}`}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span className='link link-cyan transition'>
        {isLocked && (
          <>
            <LockIcon />
            {'\u00A0'}
          </>
        )}
        {name}
      </span>
      {isFavourite && (
        <>
          {'\u00A0'}
          <StarIcon />
        </>
      )}
    </a>
    <p className={hasFailed ? 'line-through' : ''}>{description}</p>
  </div>
)

export default Lifehack
